import { Translate } from '@change-corgi/core/react/i18n';
import { Box } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

import { ResponsiveBox } from 'src/app/pages/petitionGamma/details/shared/components';

import { PetitionUrlQRCode } from '../QRCode';

import { ShareLoader } from './ShareLoader';

export function SharePetition() {
	return (
		<Box px={[16, 0]}>
			<Heading as="h2" size={['h3']} mb={16}>
				<Translate value="corgi.petition_gamma.share.title" />
			</Heading>
			<Box
				sx={{
					gap: 16,
					display: 'flex',
					flexDirection: ['column', 'row'],
				}}
			>
				<PetitionUrlQRCode />
				<Box
					sx={{
						width: '100%',
					}}
				>
					<ResponsiveBox viewportSize="small">
						<ShareLoader forMobileView />
					</ResponsiveBox>
					<ResponsiveBox viewportSize="normal">
						<ShareLoader forMobileView={false} />
					</ResponsiveBox>
				</Box>
			</Box>
		</Box>
	);
}
