import React from 'react';

import { Box } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

import { SupporterCommentCard } from './components/SupporterCommentCard';
import { ViewAllComments } from './components/ViewAllComments';
import { useSupporterComments } from './hooks/useSupporterComments';

export function SupporterVoicesComments(): React.JSX.Element | null {
	const {
		data: { supporterComments, petition },
	} = useSupporterComments();

	if (supporterComments.length === 0) {
		return null;
	}

	return (
		<Box>
			<Heading as="h3" sx={{ fontSize: '20', fontWeight: 'normal' }} my={24}>
				Featured Comments
			</Heading>
			{supporterComments.map((comment) => {
				return <SupporterCommentCard key={comment.id} supporterComment={comment} petition={petition} />;
			})}

			<ViewAllComments petitionId={petition.id} slug={petition.slug} />
		</Box>
	);
}
