import type { JSX } from 'react';

import { Redirect } from '@change-corgi/core/react/router';

import { withPrefetchedData } from 'src/shared/prefetch';
import type { PrefetchedDataProps } from 'src/shared/prefetch';

import type { UserInfoState } from 'src/app/pages/petitionGamma/shared/types';
import { isError, isLoading } from 'src/app/shared/utils/async';

import { prefetchPetitionInfo, usePetitionInfo } from '../hooks/petitionInfo';
import type { PetitionInfoState } from '../hooks/petitionInfo';

import { BannerContainer } from './BannerContainer';

type Props = Readonly<{
	slug: string;
	userInfoState: UserInfoState;
}>;
type PrefetchedData = { info: PetitionInfoState };

export const PetitionHeaderContainer = withPrefetchedData(
	({ slug, prefetchedData, userInfoState }: Props & PrefetchedDataProps<PrefetchedData>): JSX.Element | null => {
		const petitionInfoState = usePetitionInfo(slug, prefetchedData?.info);

		// TODO use a fixed height or a ghost to avoid layout shifting?
		if (isLoading(petitionInfoState) || isLoading(userInfoState)) return null;

		if (isError(petitionInfoState) && petitionInfoState.reason === 'notFound') {
			return <Redirect to="/?petition_not_found=true" />;
		}

		if (isError(petitionInfoState)) {
			throw new Error('Petition Header: Failed to load petition details', { cause: petitionInfoState.cause });
		}
		if (isError(userInfoState)) {
			throw new Error('Petition Header: Failed to load user info', { cause: userInfoState.cause });
		}

		const { info: userInfo } = userInfoState;

		return (
			<BannerContainer
				petitionId={petitionInfoState.info.id}
				emergencyBanner={petitionInfoState.info.emergencyBanner}
				signed={userInfo.signed}
			/>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchData: async (context) => {
			const {
				params: { slug },
				utilityContext,
			} = context;
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return { info: await prefetchPetitionInfo(slug!, utilityContext) };
		},
	},
);
