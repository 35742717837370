import React, { useCallback, useEffect } from 'react';

import { ButtonLink } from '@change-corgi/design-system/components/actions';
import { Flex } from '@change-corgi/design-system/layout';

import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';

type Props = {
	petitionId: string;
	slug: string;
};

export function ViewPetitionFeed({ petitionId, slug }: Props): React.JSX.Element {
	const { track } = useCampaignTracking();

	const trackViewAllClick = useCallback(() => {
		track('campaign_action_supporter_voices_view_all_click', { petition_id: petitionId });
	}, [petitionId, track]);

	useEffect(() => {
		track('campaign_action_supporter_voices_view_all_view', { petition_id: petitionId });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Flex sx={{ justifyContent: 'center' }}>
			<ButtonLink
				data-testid="supporter-voices-view-all"
				to={`/p/${slug}/feed`}
				variant="secondary"
				my={0}
				sx={{ width: ['100%', 'auto'] }}
				onClick={trackViewAllClick}
			>
				{/* TODO(CHANGE-67982): Replace with i18n key */}
				View all supporter voices
			</ButtonLink>
		</Flex>
	);
}
