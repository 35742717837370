import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { getContactDmConfig } from 'src/app/pages/petitionGamma/details/pageContext';

import type {
	GammaPetitionDetailsPageDecisionMakersQuery,
	GammaPetitionDetailsPageDecisionMakersQueryVariables,
} from './decisionMakers.graphql';

export type DecisionMakers = NonNullable<
	GammaPetitionDetailsPageDecisionMakersQuery['petition']
>['decisionMakersConnection']['nodes'];

export type DecisionMaker = DecisionMakers[0];

const QUERY = gql`
	query GammaPetitionDetailsPageDecisionMakers($slugOrId: String!, $numDecisionMakers: Int!, $emailEnabled: Boolean!) {
		petition: petitionBySlugOrId(slugOrId: $slugOrId) {
			id
			decisionMakersConnection(first: $numDecisionMakers) {
				nodes {
					id
					slug
					displayName
					title
					publiclyVisible
					email @include(if: $emailEnabled)
					photo {
						id
						userMedium {
							url
							processing
						}
					}
					targetPetitionAssociationData {
						daysSinceNotified
					}
				}
			}
		}
	}
`;

export async function getDecisionMakers(
	petitionSlugOrId: string,
	numDecisionMakers: number,
	utilityContext: UtilityContext,
): Promise<DecisionMakers> {
	const {
		gql: { fetch },
	} = utilityContext;

	const { emailEnabled } = await getContactDmConfig(utilityContext);

	const { data } = await fetch<
		GammaPetitionDetailsPageDecisionMakersQuery,
		GammaPetitionDetailsPageDecisionMakersQueryVariables
	>({
		query: QUERY,
		variables: {
			slugOrId: petitionSlugOrId,
			numDecisionMakers,
			emailEnabled,
		},
	});

	if (!data?.petition?.decisionMakersConnection) throw new Error('Unable to fetch decision makers');

	return data.petition.decisionMakersConnection.nodes;
}
