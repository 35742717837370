import { useMemo } from 'react';

import { usePetitionDetailsPageData } from '../../../../pageContext';

type Result = ModelHookResult<{
	isShown: boolean;
	count: number;
}>;

export const useUpdates = (): Result => {
	const {
		data: {
			petition: { petitionUpdatesConnection },
		},
	} = usePetitionDetailsPageData();

	const updates = useMemo(() => {
		const { totalCount } = petitionUpdatesConnection;

		return {
			data: {
				isShown: totalCount > 0,
				count: totalCount,
			},
		};
	}, [petitionUpdatesConnection]);

	return updates;
};
