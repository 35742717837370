import React, { type PropsWithChildren, useCallback, useContext, useEffect, useRef } from 'react';

import type { Position } from '../../../../shared/types';
import { GeoMapContext, useGeoMapContextValue } from '../context';

export function GeoMap({
	center,
	zoom,
	children,
}: PropsWithChildren<{ center: Position; zoom: number }>): React.JSX.Element {
	const value = useGeoMapContextValue();

	return (
		<GeoMapContext.Provider value={value}>
			<GeoMapInner center={center} zoom={zoom} />
			{children}
		</GeoMapContext.Provider>
	);
}

function GeoMapInner({ center, zoom }: { center: Position; zoom: number }): JSX.Element {
	const mapRef = useRef(null);
	const {
		actions: { setMap, drawMap },
	} = useContext(GeoMapContext);

	const drawMapAndSaveInContext = useCallback(async () => {
		setMap(await drawMap(mapRef.current as unknown as HTMLElement, center, zoom));
	}, [drawMap, setMap, center, zoom]);

	useEffect(() => {
		if (zoom) {
			void drawMapAndSaveInContext();
		}
	}, [zoom, drawMapAndSaveInContext]);

	return (
		<div
			ref={mapRef}
			sx={{
				width: '100%',
				height: 400,
				borderRadius: '10px',
			}}
		></div>
	);
}
