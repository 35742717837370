import { useContext, useEffect } from 'react';

import type { Position } from '../../../../shared/types';
import { GeoMapContext } from '../context';

export function GeoMarker({ location, radius }: { location: Position; radius: number }): React.JSX.Element | null {
	const {
		data: { map },
		actions: { drawCircle },
	} = useContext(GeoMapContext);

	useEffect(() => {
		if (map) {
			void drawCircle(map, location, radius);
		}
	}, [map, location, radius, drawCircle]);

	return null;
}
