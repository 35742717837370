import { useMemo } from 'react';

import { useMediaHitsContext } from '../../../MediaHitsContainer/shared/context';
import { useMediaHitsPermissions } from '../../../MediaHitsContainer/shared/hooks/useMediaHitsPermissions';

type Result = ModelHookResult<{
	isShown: boolean;
	count: number;
}>;

export const useMediaHits = (): Result => {
	const {
		data: { mediaEmbeds },
	} = useMediaHitsContext();
	const {
		data: { canAdd },
	} = useMediaHitsPermissions();

	const validMediaEmbeds = useMemo(() => {
		return mediaEmbeds.filter((mediaRendered) => mediaRendered.valid);
	}, [mediaEmbeds]);

	const mediaHits = useMemo(() => {
		const mediaEmbedsCount = (canAdd ? mediaEmbeds : validMediaEmbeds).length;

		return {
			data: {
				isShown: mediaEmbedsCount > 0,
				count: mediaEmbedsCount,
			},
		};
	}, [canAdd, mediaEmbeds, validMediaEmbeds]);

	return mediaHits;
};
