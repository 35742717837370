import { Translate } from '@change-corgi/core/react/i18n';
import { Text } from '@change-corgi/design-system/typography';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

export const PetitionTimestamp: React.FC<EmptyObject> = () => {
	const {
		data: {
			petition: { createdAtLocalized },
		},
	} = usePetitionDetailsPageData();

	return (
		<Text>
			<Translate
				value="corgi.petition_gamma.details.petition_timestamp.created_at"
				replacements={{ created_at_localized: createdAtLocalized }}
			/>
		</Text>
	);
};
