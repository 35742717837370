import { useEffect } from 'react';

import { Box } from '@change-corgi/design-system/layout';
import { Heading, Text } from '@change-corgi/design-system/typography';

import { useTheSupportersContext } from '../../../TheSupporters/shared/context';
import { useSignaturesDistributionContext } from '../../shared/provider';
import { Loaders } from '../Loaders';
import { SignatureMapConfigurator } from '../SignatureMapConfigurator';
import { useSignaturesDistributionHidden } from '../SignaturesDistributionHidden';
import { SignaturesDistributionInfo } from '../SignaturesDistributionInfo';

import { useClusterPercentage } from './hooks/useClusterPercentage';
import { useFetchSignaturesDistribution } from './hooks/useFetchSignaturesDistribution';
import { useSignaturesDistribution } from './hooks/useSignaturesDistribution';
import { useZoomLevel } from './hooks/useZoomLevel';

type Props = {
	isStaff: boolean;
};

export function SignaturesDistribution({ isStaff }: Props): React.JSX.Element | null {
	const {
		data: { hasMap },
		actions: { setHasMap },
	} = useTheSupportersContext();
	const {
		data: { hasNoCluster, isNotInSignatureCountRange, noData },
	} = useSignaturesDistributionContext();

	useFetchSignaturesDistribution();
	useSignaturesDistribution(isStaff);
	useZoomLevel();
	useClusterPercentage();
	useSignaturesDistributionHidden({
		noClusterFound: hasNoCluster,
		countNotInRange: isNotInSignatureCountRange,
	});

	useEffect(() => {
		if (hasNoCluster && hasMap) {
			setHasMap(false);
		}
	}, [hasNoCluster, setHasMap, hasMap]);

	if (hasNoCluster || isNotInSignatureCountRange) {
		return null;
	}

	return (
		<Box data-qa="supporter-map-container">
			<Heading as="h3" sx={{ fontSize: '20', fontWeight: 'normal' }} mt={36} mb={24} data-qa="supporter-map-heading">
				{/* TODO(CHANGE-67982): Replace with i18n key */}
				About the supporters
			</Heading>

			<Loaders />
			{noData && <Text>No data available</Text>}
			<SignaturesDistributionInfo isStaff={isStaff} />
			{isStaff && <SignatureMapConfigurator />}
		</Box>
	);
}
