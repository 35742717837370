import { useMemo, useState } from 'react';

export const useTheSupportersContextValue = () => {
	const [hasComments, setHasComments] = useState<boolean>(true);
	const [hasVideos, setHasVideos] = useState<boolean>(true);
	const [hasMap, setHasMap] = useState<boolean>(true);

	const hasContent = useMemo(() => hasComments || hasVideos || hasMap, [hasComments, hasVideos, hasMap]);

	const value = useMemo(
		() => ({
			data: {
				hasContent,
				hasComments,
				hasVideos,
				hasMap,
			},
			actions: {
				setHasComments,
				setHasVideos,
				setHasMap,
			},
		}),
		[hasContent, hasComments, hasVideos, hasMap, setHasComments, setHasVideos, setHasMap],
	);
	return value;
};
