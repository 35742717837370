import { useEffect, useMemo } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';
import { iframely } from 'src/app/shared/utils/iframely';

import { useMediaHitsContext } from '../../../../shared/context';
import { useMediaHitsPermissions } from '../../../../shared/hooks/useMediaHitsPermissions';

type Result = ModelHookResult<{
	showSection: boolean;
	showList: boolean;
	showAdd: boolean;
	trackingData: {
		petition_id: string;
	};
}>;

export function useMediaHits(): Result {
	const {
		data: { mediaEmbeds },
	} = useMediaHitsContext();
	const {
		data: { canAdd },
	} = useMediaHitsPermissions();
	const {
		data: {
			petition: { id: petitionId },
		},
	} = usePetitionDetailsPageData();
	const track = useTracking();

	useEffect(() => {
		if (!mediaEmbeds.length) return undefined;

		return iframely.on('click', (href) => {
			const theClickedEmbed = mediaEmbeds.find((m) => m.url === href);
			void track('petition_media_feed_click', {
				url: href,
				petition_id: petitionId,
				media_source: theClickedEmbed?.mediaSource,
			});
		});
	}, [track, mediaEmbeds, petitionId]);

	return useMemo(() => {
		// Filter mediaEmbeds for media hits rendered properly
		const validMediaEmbeds = mediaEmbeds?.filter((mediaRendered) => mediaRendered.valid) || [];
		const showList = canAdd || validMediaEmbeds.length > 0;
		const showAdd = canAdd;

		const showSection = showList || showAdd;
		return {
			data: {
				showList,
				showAdd,
				showSection,
				trackingData: { petition_id: petitionId },
			},
			actions: {},
		};
	}, [canAdd, mediaEmbeds, petitionId]);
}
