import type { JSX } from 'react';

import { Separator } from '@change-corgi/design-system/components/content';
import { Box } from '@change-corgi/design-system/layout';
import { Heading } from '@change-corgi/design-system/typography';

import { ScrollTrackingWaypointContainer } from '../../../ScrollTrackingWaypoint';
import { AddMediaHit } from '../AddMediaHit';
import { MediaHitList } from '../MediaHitList';

import { useMediaHits } from './hooks/useMediaHits';

export function MediaHitsWrapper(): JSX.Element | null {
	const {
		data: { showAdd, showSection, showList, trackingData },
	} = useMediaHits();

	if (!showSection) {
		return null;
	}

	return (
		<>
			<Separator role="presentation" my={24} />
			<Box data-testid="media-hits-wrapper">
				<Heading as="h2" size={['h3']} mb={16}>
					{/* TODO(CHANGE-67982): Replace with i18n key */}
					Media Mentions
				</Heading>
				<ScrollTrackingWaypointContainer name="campaign_media_hits_view" trackingData={trackingData} />

				{showAdd ? <AddMediaHit /> : null}
				{showList ? <MediaHitList /> : null}
			</Box>
		</>
	);
}
