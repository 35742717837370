import { useEffect } from 'react';

import { usePetitionDetailsPageData } from 'src/app/pages/petitionGamma/details/pageContext';

import { useSupporterVideos } from '../../../../hooks/supporterVoices/supporterVideos';
import { useSupporterComments } from '../../../supporterVoicesComments/hooks/useSupporterComments';
import { useTheSupportersContext } from '../../../TheSupporters/shared/context';

type Result = ModelHookResult<
	{
		showSupporterVoicesSection: boolean;
	},
	EmptyObject
>;

export function useSupporterVoicesSection(): Result {
	const {
		actions: { setHasComments, setHasVideos },
	} = useTheSupportersContext();
	const {
		data: { supporterComments },
	} = useSupporterComments();

	const {
		data: { supporterVoicesData },
	} = usePetitionDetailsPageData();
	const {
		data: { supporterVideos },
	} = useSupporterVideos({ supporterVoicesData });

	const hasComments = supporterComments.length > 0;
	const hasVideos = supporterVideos.length > 0;

	useEffect(() => {
		setHasComments(hasComments);
		setHasVideos(hasVideos);
	}, [hasComments, hasVideos, setHasComments, setHasVideos]);

	return {
		data: { showSupporterVoicesSection: hasComments || hasVideos },
		actions: {},
	};
}
