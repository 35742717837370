import type { PropsWithChildren } from 'react';

import { TheSupportersContextBaseProvider } from './context';
import { useTheSupportersContextValue } from './useTheSupportersContextValue';

export function TheSupportersContextProvider({ children }: PropsWithChildren) {
	const value = useTheSupportersContextValue();

	return <TheSupportersContextBaseProvider value={value}>{children}</TheSupportersContextBaseProvider>;
}
