import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Text } from '@change-corgi/design-system/typography';

type NotifiedSinceProps = {
	daysSinceNotified: number | null | undefined;
};

export function NotifiedSince({ daysSinceNotified }: NotifiedSinceProps): JSX.Element | null {
	// not using !daysSinceNotified because daysSinceNofied = 0 is still valid here
	if (daysSinceNotified === null || daysSinceNotified === undefined) return null;

	const getNotifiedSinceText = () => {
		if (daysSinceNotified === 0)
			return <Translate value="corgi.petition_gamma.decision_makers.response_status.notified_since.today" />;
		if (daysSinceNotified === 1)
			return <Translate value="corgi.petition_gamma.decision_makers.response_status.notified_since.one_day" />;
		return (
			<Translate
				value="corgi.petition_gamma.decision_makers.response_status.notified_since.x_days"
				replacements={{ daysSinceNotified }}
			/>
		);
	};

	const notifiedSinceText = getNotifiedSinceText();

	return (
		<Text size="small" data-testid="notified-since-text">
			({notifiedSinceText})
		</Text>
	);
}
