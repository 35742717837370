import { TranslateHtml } from '@change-corgi/core/react/i18n';
import { Link } from '@change-corgi/design-system/components/actions';
import { Text } from '@change-corgi/design-system/typography';

import { useRespondAsNewDm } from './hooks/useRespondAsNewDm';

export function RespondAsNewDm({ dmName }: { dmName: string }): JSX.Element {
	const {
		data: { ask, link },
		actions: { handleNewDmActionClick },
	} = useRespondAsNewDm({ dmName });
	return (
		<Text size="small" as="p" fontWeight="normal">
			<TranslateHtml
				value="corgi.petition_gamma.decision_makers.response_status.are_you_dm"
				replacements={{ dmName }}
			/>{' '}
			<Link
				to={link}
				target="blank"
				color="primary-changeRed"
				data-qa="respond-as-new-dm"
				onClick={handleNewDmActionClick}
			>
				<Text size="small" fontWeight="normal">
					{ask}
				</Text>
			</Link>
		</Text>
	);
}
