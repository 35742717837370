import type { JSX } from 'react';

import { withI18nPlaceholders } from '@change-corgi/core/react/i18n';
import { useKnownAvailableParams } from '@change-corgi/core/react/router';
import { Box } from '@change-corgi/design-system/layout';

import I18N_PLACEHOLDERS from 'config/i18n/placeholders/petitionGamma/petition-gamma.yml';

import type { PrefetchedUserDataProps } from 'src/shared/prefetch';
import { withPrefetchedData } from 'src/shared/prefetch';

// The details folder is really the only page. This component wraps it with additional data.
// eslint-disable-next-line internal-rules/no-page-imports
import { PetitionGammaDetails } from 'src/app/pages/petitionGamma/details';

import { prefetchUserInfo, useUserInfo } from '../hooks/userInfo';
import type { UserInfo } from '../shared/types';

import { PetitionHeaderContainer } from './Header';

type PrefetchedUserData = UserInfo;

const PetitionHomeContainerInner = withPrefetchedData(
	({ prefetchedUserData }: PrefetchedUserDataProps<PrefetchedUserData>): JSX.Element | null => {
		const { slug } = useKnownAvailableParams<{ slug: string }>();

		const userInfoState = useUserInfo(slug, prefetchedUserData);

		return (
			<>
				<PetitionHeaderContainer slug={slug} userInfoState={userInfoState} />
				<Box data-qa="petition-gamma">
					<PetitionGammaDetails userInfoState={userInfoState} />
				</Box>
			</>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: () => {
			return [PetitionHeaderContainer, PetitionGammaDetails];
		},
		prefetchUserData: async (context) => {
			const {
				params: { slug },
				utilityContext,
				session,
			} = context;
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const userInfo = await prefetchUserInfo(session, slug!, utilityContext);
			return userInfo || { canEdit: false, ownPetition: false, signed: false, newPetitionPage: false };
		},
	},
);

export const PetitionHomeContainer = withI18nPlaceholders(I18N_PLACEHOLDERS, PetitionHomeContainerInner);
