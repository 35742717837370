import type { ComponentPropsWithoutRef } from 'react';

import { Separator } from '@change-corgi/design-system/components/content';
import { Box, Flex } from '@change-corgi/design-system/layout';

import { ResponsiveBox } from 'src/app/pages/petitionGamma/details/shared/components';

import { useContentSummary } from '../../hooks/useContentSummary';
import { DecisionMakersText } from '../DecisionMakersText';
import { MediaHitsText } from '../MediaHitsText';
import { UpdatesText } from '../UpdatesText';
import { VotersInfo } from '../VotersInfo';

import { MicrophoneIcon } from './icons/MicrophoneIcon';
import { NewspaperHeartMiddleIcon } from './icons/NewspaperHeartMiddleIcon';
import { USCapitolIcon } from './icons/USCapitolIcon';
import { VotersIcon } from './icons/VotersIcon';

const ContentSummaryItem: React.FC<ComponentPropsWithoutRef<typeof Box>> = ({ children, ...rest }) => {
	return (
		<Flex
			sx={{
				gap: [16, 8],
				flexBasis: 0,
				flexGrow: 1,
				flexDirection: ['row', 'column'],
				borderColor: 'primary-greyBackground',
				borderLeftStyle: ['none', 'solid'],
				borderLeftWidth: [0, 1],
				paddingLeft: [0, 16],
				// eslint-disable-next-line @typescript-eslint/naming-convention
				':first-of-type': {
					border: 'none',
					paddingLeft: 0,
				},
			}}
			{...rest}
		>
			{children}
		</Flex>
	);
};

type Props = EmptyObject;

export const ContentSummary: React.FC<Props> = () => {
	const {
		data: { sectionIsShown, decisionMakers, mediaHits, updates, voters },
	} = useContentSummary();

	if (!sectionIsShown) {
		return null;
	}

	return (
		<>
			<ResponsiveBox viewportSize="small">
				<Separator role="presentation" mt={16} />
			</ResponsiveBox>
			<Box px={[24, 0]} py={24} mt={[0, 24]}>
				<Flex
					data-testid="content-summary"
					sx={{
						gap: 16,
						flexDirection: ['column', 'row'],
						justifyContent: ['space-around', 'space-between'],
					}}
				>
					{voters.isShown && (
						<ContentSummaryItem data-testid="content-summary-voters">
							<VotersIcon fontSize={24} />
							<VotersInfo percentage={voters.percentage} />
						</ContentSummaryItem>
					)}
					{decisionMakers.isShown && (
						<ContentSummaryItem data-testid="content-summary-decision-makers">
							<USCapitolIcon fontSize={24} />
							<DecisionMakersText firstDmName={decisionMakers.first.displayName} count={decisionMakers.count} />
						</ContentSummaryItem>
					)}
					{updates.isShown && (
						<ContentSummaryItem data-testid="content-summary-updates">
							<NewspaperHeartMiddleIcon fontSize={24} />
							<UpdatesText count={updates.count} />
						</ContentSummaryItem>
					)}
					{mediaHits.isShown && (
						<ContentSummaryItem data-testid="content-summary-media-mentions">
							<MicrophoneIcon fontSize={24} />
							<MediaHitsText count={mediaHits.count} />
						</ContentSummaryItem>
					)}
				</Flex>
			</Box>
			<ResponsiveBox viewportSize="small">
				<Separator role="presentation" mb={16} />
			</ResponsiveBox>
		</>
	);
};
