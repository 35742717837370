export const VotersIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M22.9334 17.6001C22.3811 17.6001 21.9334 18.0479 21.9334 18.6001C21.9334 19.1524 22.3811 19.6001 22.9334 19.6001H26.2667V28.0002L6.60008 28.0002V19.6001H17.7334C18.2857 19.6001 18.7334 19.1524 18.7334 18.6001C18.7334 18.0479 18.2857 17.6001 17.7334 17.6001H4.60008V28.0002H3C2.44772 28.0002 2 28.4479 2 29.0002C2 29.5525 2.44772 30.0002 3 30.0002L29 30.0002C29.5523 30.0002 30 29.5525 30 29.0002C30 28.4479 29.5523 28.0002 29 28.0002H28.2667V17.6001H22.9334Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.9143 2.49273C21.1946 2.96863 21.036 3.58161 20.5601 3.86185L12.0763 8.85769L9.37288 14.9553L11.6871 17.6002H13.4957L12.4636 15.5903C12.3322 15.3345 12.3174 15.0345 12.4228 14.767C12.5282 14.4994 12.7436 14.2902 13.0142 14.1927L16.6217 12.8927C16.8793 12.7999 17.1636 12.8168 17.4084 12.9393L18.439 13.4551C18.7776 13.6246 18.9914 13.9708 18.9914 14.3494V22.6034C18.9914 22.7875 19.0548 23.0857 19.2155 23.3092C19.3395 23.4815 19.5545 23.6669 20.0526 23.6669C20.5508 23.6669 20.7658 23.4815 20.8898 23.3092C21.0505 23.0857 21.1139 22.7875 21.1139 22.6034C21.1139 22.5665 21.1159 22.5297 21.12 22.493L22.6662 8.56444C22.683 8.41283 22.7343 8.26707 22.8162 8.13833L26.4231 2.46372C26.7194 1.99763 27.3374 1.85995 27.8035 2.15622C28.2696 2.45248 28.4073 3.0705 28.111 3.53659L24.6284 9.01554L23.1132 22.665C23.102 23.1657 22.9528 23.8661 22.5135 24.4769C22.0191 25.1643 21.2035 25.6669 20.0526 25.6669C18.9018 25.6669 18.0862 25.1643 17.5918 24.4769C17.1341 23.8406 16.9914 23.1071 16.9914 22.6034V14.9671L16.8965 14.9196L14.7624 15.6886L16.0229 18.1434C16.1821 18.4534 16.1684 18.8239 15.9868 19.1213C15.8052 19.4188 15.4818 19.6002 15.1334 19.6002H11.2334C10.945 19.6002 10.6707 19.4757 10.4808 19.2587L7.44744 15.792C7.19112 15.4991 7.12807 15.084 7.28583 14.7282L10.3781 7.75359C10.4627 7.56273 10.6049 7.40314 10.7848 7.2972L19.5452 2.13846C20.0211 1.85822 20.6341 2.01683 20.9143 2.49273Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.2254 21.9073C15.6159 21.5168 15.6159 20.8836 15.2254 20.4931C14.8348 20.1026 14.2017 20.1026 13.8111 20.4931L12.0735 22.2307L10.3359 20.4931C9.94542 20.1026 9.31226 20.1026 8.92173 20.4931C8.53121 20.8836 8.53121 21.5168 8.92173 21.9073L10.6593 23.6449L8.92155 25.3827C8.53103 25.7732 8.53103 26.4064 8.92155 26.7969C9.31207 27.1874 9.94524 27.1874 10.3358 26.7969L12.0735 25.0591L13.8113 26.7969C14.2019 27.1875 14.835 27.1875 15.2255 26.7969C15.6161 26.4064 15.6161 25.7732 15.2255 25.3827L13.4877 23.6449L15.2254 21.9073Z"
		/>
	</svg>
);
