import { useTracking } from '@change-corgi/core/react/tracking';

import { usePetitionDetailsPageData, usePetitionDetailsPageFcm } from 'src/app/pages/petitionGamma/details/pageContext';
import type { DecisionMaker } from 'src/app/pages/petitionGamma/details/shared/types';
import { useQueryParamValue } from 'src/app/shared/hooks/location';

import type { AllDmResponses } from '../../../../../hooks/useDecisionMakers';

type Result = ModelHookResult<
	{
		dmHasResponded: boolean;
		showRespondAsNewDm: boolean;
		firstDmResponseUrl: string;
	},
	{
		handleReadResponseClick: () => void;
	}
>;

type UseDmResponseArgs = {
	decisionMaker: DecisionMaker;
	allDmResponses: AllDmResponses;
};

export function useDmResponse({ decisionMaker, allDmResponses }: UseDmResponseArgs): Result {
	const {
		data: {
			petition: { id: petitionId, slug },
		},
	} = usePetitionDetailsPageData();
	const { dmCardConfig } = usePetitionDetailsPageFcm();

	const track = useTracking();

	const dmResponses = allDmResponses.filter((response) => response.decisionMaker?.id === decisionMaker.id);

	const firstDmResponseUrl = dmResponses.length > 0 ? `/p/${slug}/responses/${dmResponses[0].id}` : '';

	const handleReadResponseClick = () => {
		void track('petition_gamma_read_dm_response_click', {
			petition_id: petitionId,
		});
	};

	const currentSource = useQueryParamValue('utm_source');

	return {
		data: {
			dmHasResponded: dmResponses.length > 0,
			firstDmResponseUrl,
			showRespondAsNewDm:
				(dmCardConfig?.newDmCtaSources ?? []).includes(currentSource ?? '') && !!dmCardConfig?.newDmCtaEnabled,
		},
		actions: { handleReadResponseClick },
	};
}
