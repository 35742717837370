import type { JSX } from 'react';

import { BreadcrumbLink, Breadcrumbs } from '@change-corgi/design-system/components/navigation';

import { useTagBreadcrumbs } from './hooks/useTagBreadcrumbs';

export function TagBreadcrumbs(): JSX.Element | null {
	const {
		data: { ask, displayed, slug, tag },
	} = useTagBreadcrumbs();

	if (!displayed) return null;

	return (
		<Breadcrumbs>
			<BreadcrumbLink to="/">Change.org</BreadcrumbLink>
			<BreadcrumbLink to={`/t/${tag?.slug}`}>{tag?.name}</BreadcrumbLink>
			<BreadcrumbLink to={`/p/${slug}`}>{ask}</BreadcrumbLink>
		</Breadcrumbs>
	);
}
