import React, { useEffect } from 'react';

import { ClientRender } from '@change-corgi/core/react/ssr/render';

import { useTheSupportersContext } from '../TheSupporters/shared/context';

import { SignaturesDistribution } from './components/SignaturesDistribution';
import { useSignaturesDistributionHidden } from './components/SignaturesDistributionHidden';
import { useShouldTheUserSeeTheMap } from './hooks/useShouldTheUserSeeTheMap';
import { SignaturesDistributionContextProvider } from './shared/provider';

export const SignaturesDistributionContainerInner: React.FC = () => {
	const {
		data: { hasMap },
		actions: { setHasMap },
	} = useTheSupportersContext();
	const {
		shouldSeeTheMap,
		isStaff,
		configuration,
		userNotInVariantGroup,
		petitionIsTooOld,
		petitionIsBlocked,
		countNotInRange,
	} = useShouldTheUserSeeTheMap();
	useSignaturesDistributionHidden({
		userNotInVariantGroup,
		petitionIsTooOld,
		petitionIsBlocked,
		countNotInRange,
	});

	useEffect(() => {
		if (!shouldSeeTheMap && hasMap) {
			setHasMap(false);
		}
	}, [shouldSeeTheMap, hasMap, setHasMap]);

	if (!shouldSeeTheMap) {
		return null;
	}

	return (
		<SignaturesDistributionContextProvider configuration={configuration}>
			<SignaturesDistribution isStaff={isStaff} />
		</SignaturesDistributionContextProvider>
	);
};

export const SignaturesDistributionContainer: React.FC = () => {
	return (
		// TODO(CHANGE-67838): The inner component uses loginState to render staff features, which doesn't work with SSR.
		<ClientRender>
			<SignaturesDistributionContainerInner />
		</ClientRender>
	);
};
