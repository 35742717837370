import { Heading } from '@change-corgi/design-system/typography';

export const TheSupportersTitle: React.FC<EmptyObject> = () => {
	return (
		<Heading as="h2" sx={{ fontSize: '24' }} mt={24}>
			{/* TODO(CHANGE-67982): Replace with i18n key */}
			The Supporters
		</Heading>
	);
};
