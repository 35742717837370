import { useEffect } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';
import { Box } from '@change-corgi/design-system/layout';

import { usePetitionDetails } from '../../../../hooks/petitionDetails';
import { useSignaturesDistributionContext } from '../../shared/provider';
import type { SignaturesCluster } from '../../shared/types';

import { GeoMap } from './components/GeoMap';
import { GeoMarker } from './components/GeoMarker';

type Props = {
	signaturesClusters: SignaturesCluster[];
	clusterPercentage: number;
	isStaff: boolean;
};

export function SignatureMap({ signaturesClusters, clusterPercentage, isStaff }: Props): React.JSX.Element | null {
	const {
		center,
		contributors: [{ postalCode }],
	} = signaturesClusters[0];
	const {
		data: { clusterSize, zoomLevel },
	} = useSignaturesDistributionContext();
	const { id } = usePetitionDetails();
	const track = useTracking();

	useEffect(() => {
		void track('petition_sign_map_enabled', {
			petition_id: id,
			cluster_percentage: clusterPercentage,
			is_staff: isStaff ? 'true' : 'false',
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!zoomLevel || !clusterSize) {
		return null;
	}

	return (
		<Box data-qa="supporter-map" data-testid="div-around-sign-map" title={`map centered around zipcode ${postalCode}`}>
			<GeoMap center={center} zoom={zoomLevel}>
				<GeoMarker location={center} radius={clusterSize} />
			</GeoMap>
		</Box>
	);
}
