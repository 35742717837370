import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { Session } from 'src/shared/session';

import type { UserInfo } from 'src/app/pages/petitionGamma/shared/types';
import { getViewerSignature } from 'src/app/shared/api/signature';

import { getUserPetitionInfo } from './userPetitionInfo';

const newPetitionPage = true;

/**
 * This is mostly a duplicate of `src/app/pages/petition/api/userInfo/index.ts` but there is one
 * important difference: the petition page needs to check whether the user should see the new
 * petition page, so it does an FCM and experiment check. Here, within the new petition page,
 * re-checking that is unnecessary because this will only be prefetched/rendered if the user has
 * arrived at the experimental page.
 */
export async function getUserInfo(
	session: Session | undefined,
	slug: string,
	utilityContext: UtilityContext,
): Promise<UserInfo> {
	if (!session) {
		return {
			ownPetition: false,
			canEdit: false,
			signed: false,
			newPetitionPage,
		};
	}

	const userId = session.user?.id;
	if (!userId) {
		return {
			ownPetition: false,
			canEdit: false,
			signed: false,
			newPetitionPage,
		};
	}

	const [signature, petitionInfo] = await Promise.all([
		getViewerSignature(slug, utilityContext),
		getUserPetitionInfo(slug, utilityContext),
	]);

	const ownPetition = petitionInfo?.user?.id === userId;
	const canEdit = petitionInfo?.viewerCanEdit;

	return {
		ownPetition,
		canEdit,
		newPetitionPage,
		signed: !!signature,
	};
}
