import { createContext, useMemo, useState } from 'react';

import { drawCircle, drawMap } from './googlemaps';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const GeoMapContext = createContext<GeoMapContextValueType>(null as unknown as GeoMapContextValueType);

export type GeoMapContextValueType = {
	data: {
		map: google.maps.Map;
	};
	actions: {
		setMap: React.Dispatch<google.maps.Map>;
		drawCircle: typeof drawCircle;
		drawMap: typeof drawMap;
	};
};

export const useGeoMapContextValue = () => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const [map, setMap] = useState<google.maps.Map>(null as unknown as google.maps.Map);

	const value = useMemo(
		() => ({
			data: {
				map,
			},
			actions: {
				setMap,
				drawCircle,
				drawMap,
			},
		}),
		[map, setMap],
	);
	return value;
};
