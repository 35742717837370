import { Box } from '@change-corgi/design-system/layout';

import { QRCodeDownload } from 'src/app/shared/components/qrcode';

import { usePetitionUrlQRCode } from './hooks/usePetitionUrlQRCode';

export function PetitionUrlQRCode() {
	const { data, actions } = usePetitionUrlQRCode();

	return (
		<Box mt={24}>
			<QRCodeDownload
				value={data.value}
				descriptionText={data.descriptionText}
				downloadLinkText={data.downloadLinkText}
				downloadFilename={data.downloadFilename}
				onDownload={actions.handleDownload}
			/>
		</Box>
	);
}
