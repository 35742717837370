import { Loader as GoogleApiLoader } from '@googlemaps/js-api-loader';

import { googleMapsApiClientKey } from 'config/google';

import type { Position } from '../../../shared/types';

export const getGoogleApiLoader = () =>
	new GoogleApiLoader({
		apiKey: googleMapsApiClientKey,
		version: 'weekly',
		libraries: ['maps', 'marker'],
	});

export const drawMap = async (element: HTMLElement, center: Position, zoomLevel: number) => {
	const google = await getGoogleApiLoader().load();
	const { Map } = google.maps;
	return new Map(element, {
		zoom: zoomLevel,
		center: { lat: center.latitude, lng: center.longitude },
		mapId: 'signatures_distribution_map',
		disableDefaultUI: true,
	});
};

export const drawCircle = async (map: google.maps.Map, position: Position, radius: number) => {
	const google = await getGoogleApiLoader().load();
	const { Circle } = google.maps;
	// eslint-disable-next-line no-new
	new Circle({
		map,
		strokeColor: '#FF0000',
		strokeOpacity: 0.8,
		strokeWeight: 2,
		fillColor: '#FF0000',
		fillOpacity: 0.35,
		center: { lat: position.latitude, lng: position.longitude },
		radius: radius * 100000,
	});
};
