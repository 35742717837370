import { useTracking } from '@change-corgi/core/react/tracking';

import { usePetitionDetailsPageData, usePetitionDetailsPageFcm } from 'src/app/pages/petitionGamma/details/pageContext';

type Result = ModelHookResult<
	{
		ask: string;
		link: string;
	},
	{
		handleNewDmActionClick: () => void;
	}
>;

function createMailtoLink({ dmName, title, slug }: { dmName: string; title: string | null; slug: string }): string {
	const recipient = 'decision.maker@change.org';
	const subject = 'Petition Response';
	const petitionUrl = `https://www.change.org/p/${slug}?utm_source=dm_emails_change`;

	const body = [
		`Hello ${dmName} - We are excited you are engaging with the petition:\n${title ? `"${title}": ${petitionUrl}` : petitionUrl}`,
		'My name is Meg, and I lead the Decision Makers Support Team at Change.org. Our goal is to provide you with the support and tools to engage with your constituents and community.',
		'To get started, please answer the questions below, hit send, and we’ll take it from there:',
		'1. Would you like us to share a response on your behalf? This can be a brief update on the issue or anything you’d like communicated to your constituents.\n[ADD YOUR RESPONSE]',
		'2. When is a good time for us to connect? We’d like to share more about the petition, its supporters, and learn how Change.org can better serve decision makers like you. Please provide a day, time, and contact number:\n[ADD YOUR RESPONSE]',
		'We look forward to connecting with you soon and helping you use Change.org to engage with your constituents.',
		'Meg and the Decision Makers Support Team',
	].join('\n\n');

	const encodedSubject = encodeURIComponent(subject);
	const encodedBody = encodeURIComponent(body);

	return `mailto:${recipient}?subject=${encodedSubject}&body=${encodedBody}`;
}

export function useRespondAsNewDm({ dmName }: { dmName: string }): Result {
	const {
		data: {
			petition: { id: petitionId, title, slug },
		},
	} = usePetitionDetailsPageData();
	const { dmCardConfig } = usePetitionDetailsPageFcm();

	const track = useTracking();

	const handleNewDmActionClick = () => {
		void track('petition_gamma_new_dm_action', {
			petition_id: petitionId,
		});
	};

	const urlObj = dmCardConfig?.newDmCtaLink && new URL(dmCardConfig.newDmCtaLink);
	if (urlObj) urlObj?.searchParams?.append('utm_campaign', petitionId);

	return {
		data: {
			ask: dmCardConfig?.newDmCtaCopy || 'Respond now',
			link: urlObj?.toString() || createMailtoLink({ dmName, title, slug }),
		},
		actions: { handleNewDmActionClick },
	};
}
