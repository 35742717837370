import { createMandatoryContext } from '@change-corgi/core/react/context';

import type { TheSupportersContextValueType } from './types';

export const {
	Context: TheSupportersContext,
	Provider: TheSupportersContextBaseProvider,
	useContext: useTheSupportersContext,
} = createMandatoryContext<TheSupportersContextValueType>(undefined, {
	name: 'TheSupportersContext',
});
