import React from 'react';

import { TranslatePlural } from '@change-corgi/core/react/i18n';
import { Box } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

type Props = {
	percentage: number;
};

export const VotersInfo: React.FC<Props> = ({ percentage }) => {
	const roundedPercentage = Math.round(percentage * 100);

	return (
		<Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
			<Text>
				{/* TODO: how do we handle languages that have a space between value and % */}
				<Text fontWeight="bold">{roundedPercentage}%&nbsp;</Text>
				<TranslatePlural value="corgi.petition_gamma.content_summary.voters.label" count={roundedPercentage} />
			</Text>
		</Box>
	);
};
