import { useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

type Result = ModelHookResult<
	undefined,
	{
		handleDmProfileLinkClick: (dmId: string) => Promise<void>;
	}
>;

export function useDecisionMakerCard(): Result {
	const track = useTracking();

	const handleDmProfileLinkClick = useCallback(
		async (decisionMakerId: string) => {
			await track('decision_maker_profile_link_click', {
				source_location: 'petition_details_gamma',
				decision_maker_id: decisionMakerId,
			});
		},
		[track],
	);

	return {
		actions: {
			handleDmProfileLinkClick,
		},
	};
}
