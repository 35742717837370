import { useKnownAvailableParams } from '@change-corgi/core/react/router';

import { withPrefetchedData } from 'src/shared/prefetch';

import type { PetitionGammaCommonProps } from '../../shared/types';

import { CommonPetitionDetails } from './CommonPetitionDetails';

export const PetitionGammaDetails = withPrefetchedData(
	({ userInfoState }: PetitionGammaCommonProps): JSX.Element => {
		const { slug } = useKnownAvailableParams<{ slug: string }>();
		return <CommonPetitionDetails slug={slug} userInfoState={userInfoState} />;
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [CommonPetitionDetails],
	},
);
